import React, {
  useEffect, useState 
} from 'react'

import { Slider } from 'src/ui/Slider'
import styled from 'styled-components'
import { ProductOptions } from 'src/features/catalog/ProductOptions'
import { Button } from 'src/ui/Button'
import { BackButton } from 'src/features/catalog/BackButton'
import { useParams } from 'react-router-dom'
import { Preloader } from 'src/ui/Preloader'
import { catalogApi } from 'src/features/api'
import { useBasket } from 'src/features/basket/useBasket'
import { getBasketProducts } from 'src/features/basket/selectors'
import { Counter } from 'src/ui/Counter'

export const ProductPage = ({ className = '' }) => {
  const { id: productId } = useParams()

  const products = getBasketProducts()

  const [loading, toggleLoading] = useState(true)
  const [data, setData] = useState({})

  const {
    addProduct, removeProduct 
  } = useBasket()

  const productCountInBasket = products.find(product => product.id === Number(productId))?.count || 0

  useEffect(() => {
    const fetchData = async () => {
      const response = await catalogApi.getProductInfo(productId)

      setData({
        ...response.data.attributes, id: Number(productId)
      })
      toggleLoading(false)
    }

    fetchData()
  }, [productId])

  const handleAddProductClick = () => {
    addProduct(data)
  }

  const handleRemoveProductClick = () => {
    removeProduct(data)
  }

  return (
    <ProductPageBox className={className}>
      <TopRow>
        <BackButton />
      </TopRow>

      {
        loading &&
        <PreloaderBox>
          <Preloader />
        </PreloaderBox>
      }

      {
        !loading &&
        <>
          <Slider images={data.images.data.map(img => img.attributes.url)} />

          <Title>
            {data.name}
          </Title>

          {
            !!data.vendorCode &&
            <Articul>Арт. {data.vendorCode}</Articul>
          }

          {
            !!data.options &&
            <MiddleRow>
              <MiddleColumn>
                <OptionLabel>
                  {data.options[0]?.name}
                </OptionLabel>
                <StyledProductOptions
                  options={data.options[0]?.values}
                  type={data.options[0]?.type}
                />
              </MiddleColumn>

              <MiddleColumn>
                <OptionLabel>
                  {data.options[1]?.name}
                </OptionLabel>
                <StyledProductOptions
                  options={data.options[1]?.values}
                  type={data.options[1]?.type}
                />
              </MiddleColumn>
            </MiddleRow>
          }
          

          <PriceRow>
            <PriceLeft>
              {!!data.old_price && <OldPrice>{data.old_price} ₽</OldPrice>}
              <Price>{data.price} ₽</Price>
            </PriceLeft>

            {
              productCountInBasket === 0 &&
              <Button onClick={handleAddProductClick}>
                В корзину
              </Button>
            }

            {
              productCountInBasket !== 0 &&
              <Counter
                count={productCountInBasket}
                onPlus={handleAddProductClick}
                onMinus={handleRemoveProductClick}
              />
            }
            
          </PriceRow>

          {
            !!data.description &&
            <Description>
              <DescriptionTitle>
                Описание
              </DescriptionTitle>
              {data.description}
            </Description>
          }
          
        </>
      }
    </ProductPageBox>
  )
}

const ProductPageBox = styled.div`
  padding: 16px 16px 90px;
`

const Title = styled.p`
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.main};
`

const Articul = styled.p`
  margin-top: 10px;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.quaternary};
`

const MiddleRow = styled.div`
  margin-top: 16px;
  display: flex;
`

const MiddleColumn = styled.div`
  width: 49%;
`

const OptionLabel = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.quaternary};
`

const StyledProductOptions = styled(ProductOptions)`
  margin-top: 8px;
  width: 100%;
`

const Price = styled.p`
  margin-top: 4px;
  font-weight: 500;
  font-size: 17px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.main};
`

const OldPrice = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.5px;
  text-decoration-line: line-through;
  color: ${props => props.theme.text.quaternary};
`

const PriceRow = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const PriceLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const Description = styled.div`
  margin-top: 25px;
  padding-top: 23px;
  border-top: 0.5px solid rgba(60, 60, 67, 0.29);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.main};
`

const DescriptionTitle = styled.p`
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.main};
`

const TopRow = styled.div`
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
`

const PreloaderBox = styled.div`
  height: calc(100vh - 350px);
  position: relative;
`